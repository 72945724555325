import React from 'react';
import DataSection from '../../library/data-section';
import CheckboxWithLabel from '../../library/CheckboxWithLabel';
import './styles/index';

type Props = {
  mapMarkers?: string[];
  userEditingStrategy: 'update' | 'suggest' | 'none';
};

const MapMarkers = function (props: Props) {
  const n =
    props.mapMarkers && props.mapMarkers.length > 0
    && props.mapMarkers;

  const mapMarkerBase = {
      'natural wonder': false,
      'iconic attraction': false,
      'local favorite': false,
      'hidden gem': false,
      'road trip classic': false,
      'certified weird': false,
      'historic treasure': false,
      'ai favorite': false
    }

  // mapMarkers is an array so using it's values we construct a map to operate on
  const t = n ?
    n.reduce(
      (p, c) => {
        const n = c.toLowerCase();
        p[n] = true;
        return p;
      },
       mapMarkerBase
    ): mapMarkerBase


  const [markerMap, setMarkerMap] = React.useState(t);
  const [savedMarkerMap, setSavedMarkerMap] = React.useState(t);

  const markerArray = (map) => {
    return Object.keys(map).reduce((p, c) => {
      if (map[c]) p.push(c);
      return p;
    }, []);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const savedMarkerArray = markerArray(savedMarkerMap) || [];

  return (
    <div
      data-cy="rt-map-markers"
      className="editor__roadtrippers__map__markers"
    >
      <DataSection
        cyEdit="rt-map-markers-edit"
        cyView="rt-map-markers-view"
        sectionTitle="Place Markers"
        viewComponent={
          <div className="editor__roadtrippers__map__markers editor__roadtrippers__map__markers--view">
            {savedMarkerArray.length > 0 ? (
              savedMarkerArray.map((m) => (
                <span
                  className="editor__roadtrippers__map__marker"
                  data-cy="rt-map-markers-view-marker"
                  key={m.replace(' ', '-')}
                >
                  {capitalizeFirstLetter(m)}
                </span>
              ))
            ) : (
              <div className="text-muted">none</div>
            )}
          </div>
        }
        editComponent={
          <div
            data-cy="rt-map-markers-edit-checkboxes"
            className={
              'editor__roadtrippers__map__markers editor__roadtrippers__map__markers--edit'
            }
          >
            {Object.keys(markerMap).map((k) => (
              <div
                className="editor__roadtrippers__map__marker"
                key={k}
                data-cy={
                  'rt-map-marker-edit-checkbox-' + k.split(' ').join('-')
                }
              >
                <CheckboxWithLabel
                  onChange={() => {
                    setMarkerMap({ ...markerMap, [k]: !markerMap[k] });
                  }}
                  label={capitalizeFirstLetter(k)}
                  checked={markerMap[k]}
                />
              </div>
            ))}
          </div>
        }
        params={{
          listing:
          {
            road_trip_details:
            {
              map_markers: markerArray(markerMap)
            }
          }
        }}
        onSuccess={() => {
          setSavedMarkerMap(markerMap);
        }}
        reset={() => {
          setMarkerMap(savedMarkerMap);
        }}
        userEditingStrategy={props.userEditingStrategy}
      />
    </div>
  );
};

export default MapMarkers;
